import {Controller} from "stimulus"

export default class extends Controller {
    // static targets = ["nextLink", "content"]
    static targets = ["bottleContainer"]

    connect() {
        this.registerClickHandler();
    }

    registerClickHandler() {
        this.buttons = this.element.querySelectorAll('nav.pagination a'); // Zugriff auf das Button-Element mit spezifischem CSS-Selektor
        this.buttons.forEach(
            button => button.addEventListener('click', this.loadMore.bind(this))
        )
    }

    loadMore(event) {
        console.log("ÄÄ");
        event.preventDefault();
        let url = event.currentTarget.href

        fetch(url, {
            headers: {'Accept': 'text/vnd.turbo-stream.html'}
        })
            .then(response => response.text())
            .then(html => {
                this.bottleContainerTarget.innerHTML = html;
                this.registerClickHandler();
            })
            .catch(error => console.error('Error loading more content:', error))

    }
}
