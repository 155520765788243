import {Controller} from "stimulus"

export default class extends Controller {
    // static targets = ["nextLink", "content"]
    static targets = ["tabContainer"]

    connect() {
        this.registerTabClickHandler();
        this.registerClickHandler();
        this.initTab();
    }

    registerTabClickHandler() {
        this.buttons = this.element.querySelectorAll('.tabs ul li a'); // Zugriff auf das Button-Element mit spezifischem CSS-Selektor
        this.buttons.forEach(
            button => button.addEventListener('click', this.loadTab.bind(this))
        )
        this.buttons = this.element.querySelectorAll('.tabs ul li'); // Zugriff auf das Button-Element mit spezifischem CSS-Selektor
        this.buttons.forEach(
            button => button.addEventListener('click', this.activateTab.bind(this))
        )

    }

    registerClickHandler() {
        this.buttons = this.element.querySelectorAll('nav.pagination a'); // Zugriff auf das Button-Element mit spezifischem CSS-Selektor
        this.buttons.forEach(
            button => button.addEventListener('click', this.loadMore.bind(this))
        )
    }

    initTab() {
        const anchor = window.location.hash; // Dies gibt den Anker (z.B. "#section") zurück
        if (anchor) {
            console.log("Anchor in der URL gefunden:", anchor);
            // Hier kannst du beliebige Aktionen durchführen
        } else {
            console.log("Kein Anchor in der URL");
        }
    }

    activateTab(event) {
        const tabs = document.querySelectorAll(".tabs ul li")
        // Entferne die Klasse von allen Divs
        tabs.forEach(tab => {
            tab.classList.remove("is-active")
        })

        event.currentTarget.classList.add("is-active")
        event.preventDefault();

        const tabTitles = document.querySelectorAll(".tabs ul li:not(.is-active) span.text")
        tabTitles.forEach(tab => {
            tab.classList.add("is-hidden-touch")
        })

        const activeTabTitle = document.querySelectorAll(".tabs ul li.is-active span.text")
        activeTabTitle.forEach(tab => {
            tab.classList.remove("is-hidden-touch")
        })
    }

    loadTab(event) {
        this.tabContainerTarget.innerHTML = "";
        event.preventDefault();
        let url = event.currentTarget.href

        fetch(url, {
            headers: {'Accept': 'text/vnd.turbo-stream.html'}
        })
            .then(response => response.text())
            .then(html => {
                this.tabContainerTarget.innerHTML = html;
                this.registerClickHandler();

            })
            .catch(error => console.error('Error loading more content:', error))

    }

    loadMore(event) {
        event.preventDefault();
        let url = event.currentTarget.href

        fetch(url, {
            headers: {'Accept': 'text/vnd.turbo-stream.html'}
        })
            .then(response => response.text())
            .then(html => {
                this.tabContainerTarget.innerHTML = html;
                this.registerClickHandler();
            })
            .catch(error => console.error('Error loading more content:', error))

    }
}
