import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["gross", "locale", "name", "subtotal", "price", "slug", "vintage", "volume", "winery"];
    numberFormatCurrency;
    numberFormatEn;

    connect() {
        console.log('connected');
        const locale = this.localeTarget.value;
        this.numberFormatCurrency = new Intl.NumberFormat(locale, {style: 'currency', currency: 'EUR'});
        this.numberFormatEn = new Intl.NumberFormat('en-EN');
    }

    generateSlug() {
        const name = this.nameTarget.value.trim();
        const vintage = this.vintageTarget.value.trim();
        const volume = this.volumeTarget.value.trim();
        const winery = this.wineryTarget.selectedOptions[0].text;

        let bottleSize = '';
        if (volume == '1,5' || volume == '1.5') {
            bottleSize = '-magnum'
        } else if (volume == '3,0' || volume == '3.0') {
            bottleSize = '-doppelmagnum'
        }

        const slug = this.slugTarget;
        let slugValue = `${winery}-${name}-${vintage}${bottleSize}`;
        slugValue = slugValue.toLowerCase();
        slugValue = slugValue.replaceAll(" ", "-");
        slugValue = encodeURIComponent(slugValue)

        slug.value = slugValue;

    }

    calculateNet() {
        const netInput = this.subtotalTarget;
        const priceInput = this.priceTarget;
        const grossInput = this.grossTarget;

        grossInput.value = grossInput.value.replace(",", ".");

        const priceGross = Number.parseFloat(grossInput.value);

        if (priceGross) {
            const priceNet = priceGross / 1.19
            netInput.value = this.numberFormatCurrency.format(priceNet)
            priceInput.value = this.numberFormatEn.format(priceNet)
        }
    }

    calculateGross() {
        const netInput = this.subtotalTarget;
        const priceInput = this.priceTarget;
        const grossInput = this.grossTarget;

        netInput.value = netInput.value.replace(",", ".");

        const priceNet = Number.parseFloat(netInput.value);

        if (priceNet) {
            const priceGross = priceNet * 1.19
            grossInput.value = this.numberFormatCurrency.format(priceGross)
            priceInput.value = this.numberFormatEn.format(priceNet)
        }
    }

}
