import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="commission"
export default class extends Controller {
    static targets = ["ask", "askAll", "commission", "commissionAll", "input", "inputMarket", "locale", "quantity", "submit", "total", "totalAll", "value", "price"]
    numberFormat;

    connect() {
        const locale = this.localeTarget.value;
        this.numberFormat = new Intl.NumberFormat(locale, {style: 'currency', currency: 'EUR'});
        this.askTarget.innerHTML = this.numberFormat.format(0);
        this.commissionTarget.innerHTML = this.numberFormat.format(0);
        this.totalTarget.innerHTML = this.numberFormat.format(0);
        const quantity = this.quantityTarget.value;
        if (quantity > 1) {
            this.askAllTarget.innerHTML = this.numberFormat.format(0);
            this.commissionAllTarget.innerHTML = this.numberFormat.format(0);
            this.totalAllTarget.innerHTML = this.numberFormat.format(0);

        }
    }

    updateTable(price, quantity) {
        const ask = this.askTarget;
        const commission = this.commissionTarget;
        const total = this.totalTarget;

        ask.innerHTML = this.numberFormat.format(price);
        commission.innerHTML = this.numberFormat.format(price * 0.1);
        total.innerHTML = this.numberFormat.format(price * 1.1);

        if (quantity > 1) {
            const askAll = this.askAllTarget;
            const commissionAll = this.commissionAllTarget;
            const totalAll = this.totalAllTarget;
            askAll.innerHTML = this.numberFormat.format(price * quantity);
            commissionAll.innerHTML = this.numberFormat.format(price * 0.1 * quantity);
            totalAll.innerHTML = this.numberFormat.format(price * 1.1 * quantity);
        }

    }

    updateCommission() {
        const quantity = this.quantityTarget.value;
        const input = this.inputTarget;
        const priceInput = this.priceTarget;

        input.value = input.value.replace(",", ".");
        const price = Number.parseFloat(input.value) | 0;

        this.setSubmitButtonState(price);
        this.updateTable(price, quantity);

        priceInput.value = price;
    }

    updateComissionByMarketPrice() {
        const quantity = this.quantityTarget.value;
        const input = this.inputTarget;
        const inputMarket = this.inputMarketTarget;
        const priceInput = this.priceTarget;

        inputMarket.value = inputMarket.value.replace(",", ".");

        const price = (Number.parseFloat(inputMarket.value) | 0) / 1.1;
        input.value = price.toPrecision(2);

        this.setSubmitButtonState(price);
        this.updateTable(price, quantity);

        priceInput.value = price;
    }

    setSubmitButtonState(price) {
        const submitButton = this.submitTarget;

        if (price && price > 0) {
            submitButton.removeAttribute("disabled")
        } else {
            submitButton.setAttribute("disabled", true)
        }
    }

    updateInput() {
        const input = this.inputTarget;
        const price = Number.parseFloat(input.value) | 0;
        input.value = this.numberFormat.format(price);

        const inputMarket = this.inputMarketTarget;
        inputMarket.value = this.numberFormat.format(price * 1.1);
    }

}
