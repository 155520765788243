import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["checkboxContainer"]

    connect() {
    }

    toggleCheckbox(event) {
        if (event.target.type == "checkbox") {
            return;
        }
        this.checkboxContainerTarget.checked = !this.checkboxContainerTarget.checked;
    }

}
